import { Component, OnInit } from '@angular/core';
import { IRegister } from './register.interface';
import { RegisterService } from './register.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss']
})

export class RegisterPage implements OnInit {
  public environment = environment

  constructor() { }
  ngOnInit() { }
}
