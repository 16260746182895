import { Component, Input } from '@angular/core';
import { FavoritesService } from '@services/favorites.service';
import { IProduct } from '@services/products.service';
import { StorageService } from '@utils/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'button-add-favorite',
  templateUrl: './add-favorite.component.html',
  styleUrls: ['./add-favorite.component.scss']
})

export class ButtonAddFavoriteComponent {
  public environment = environment
  @Input() product!: IProduct
  public btnFavStatus = 'default'
  public favorites = []

  constructor(
    private favoritesService: FavoritesService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.storageService.watch('favorites').subscribe(() => {
      this.favorites = this.favoritesService.getlistStorage()

      if (this.favorites.find((elm: any) => elm.inventoryId === this.product.inventoryId) !== undefined) {
        this.btnFavStatus = 'success'
      }
    })
  }

  toggleFavorites = () => {
    if (this.btnFavStatus === 'default') {
      this.btnFavStatus = 'loading'

      this.favoritesService.add(this.product.inventoryId)

      this.btnFavStatus = 'success'
    } else {
      this.btnFavStatus = 'loading'

      this.favoritesService.remove(this.product.inventoryId)

      this.btnFavStatus = 'default'
    }
  }
}
