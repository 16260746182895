import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { StorageService } from '@utils/storage.service';

@Injectable({
  providedIn: 'root'
})

export class FavoritesService {
  private FAVORITE_KEY: string = 'favorites';

  constructor(
    private httpService: HttpService,
    private storage: StorageService
  ) { }

  getlistCloud = () => this.httpService.get(`/v1/users/favorites`)

  getlistStorage = (): [] => {
    let favorites = JSON.parse(`${this.storage.get(this.FAVORITE_KEY)}`)

    return favorites !== null ? favorites : []
  }

  setlistStorage = (obj: JSON): void => this.storage.set(this.FAVORITE_KEY, JSON.stringify((obj !== null) ? obj : []))

  add = (item: any): void => {
    this.httpService.post(`/v1/users/favorites/${item}`, {})
      .then((response: any) => this.setlistStorage(response.data.content))
      .catch((error: HttpErrorResponse) => { })
  }

  remove = (item: any): void => {
    this.httpService.delete(`/v1/users/favorites/${item}`)
      .then((response: any) => this.setlistStorage(response.data.content))
      .catch((error: HttpErrorResponse) => { })
  }
}
