<div class="card border-0" title="{{product.name}}">
  <div class="block">
    <figure class="image m-0" [routerLink]="['/product', product.sku]" role="button">
      <ng-container *ngIf="product.image != null">
        <img src="{{environment.app.assets}}/uploads/{{product.image}}" class="card-img-top">
      </ng-container>
      <ng-container *ngIf="product.image === null">
        <img src="{{environment.app.assets}}/images/blank-image.svg" class="card-img-top">
      </ng-container>
    </figure>
    <div class="group-buttons">
      <button-add-favorite [product]="product"></button-add-favorite>
      <button type="button"
        class="btn btn-light fs-4 rounded-circle d-flex flex-column justify-content-center align-items-center">
        <i class="bi bi-share"></i>
      </button>
    </div>
    <div class="group-add-cart">
      <button-add-cart [item]="product" [settings]="{buttonColor: 'btn-light'}"></button-add-cart>
    </div>
  </div>
  <div class="card-body" [routerLink]="['/product', product.sku]" role="button">
    <p class="text-truncate fw-bold mb-1">{{product.name}}</p>
    <p class="mb-1">
      <span class="text-success fs-5 me-1">{{product.price | currency: 'MXN'}}</span>
      <small class="text-decoration-line-through text-muted opacity-50">{{product.price * 1.334 | currency:
        'MXN'}}</small>
    </p>
    <p class="mb-1">
      <ng-container *ngIf="product.stock >= 20">
        <small>Disponible {{product.stock}} piezas.</small>
      </ng-container>
      <ng-container *ngIf="product.stock < 20 && product.stock >= 10">
        <small class="text-warning">Solo quedan {{product.stock}} piezas.</small>
      </ng-container>
      <ng-container *ngIf="product.stock < 10">
        <small class="text-danger">Casi agotado.</small>
      </ng-container>
    </p>
    <p class="mb-0 text-warning">
      <span>
        <i class="bi bi-star-fill"></i>
        <i class="bi bi-star-fill"></i>
        <i class="bi bi-star-fill"></i>
        <i class="bi bi-star-half"></i>
        <i class="bi bi-star"></i>
      </span>
      <small class="ms-2">(4.8)</small>
    </p>
  </div>
</div>