import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IInputDropdownSettings } from './input-dropdown.interface';

@Component({
  selector: 'input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss']
})

export class InputDropdownComponent {
  @Input() iterator: any[] = []
  @Input() settings!: IInputDropdownSettings
  @Output() value = new EventEmitter();
  public valueIn: any = null

  ngOnInit() {
    if (this.settings.valueDefault) {
      this.valueIn = this.settings.valueDefault
    }
  }

  changeValue = (value: any) => {
    this.valueIn = value
    this.valueChanging()
  }

  @HostListener('keypress', ['$event'])
  keyPressEvent = (event: any): boolean => {
    if (this.settings.type === "numeric") {
      var charCode = (event.which) ? event.which : event.keyCode;

      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault()
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  valueChanging = () => {
    this.valueIn = String(this.valueIn)
    this.valueIn = this.valueIn.trim()

    if (this.settings.type === "numeric") {
      if (this.valueIn == "") {
        this.valueIn = 0
      }

      this.valueIn = Number(this.valueIn)
    }

    this.value.emit(this.valueIn)
  }
}
