import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarService } from '@components/sidebar/sidebar.service';
import { CartService, ICart } from '@services/cart.service';
import { FavoritesService } from '@services/favorites.service';
import { AuthService } from '@utils/auth.service';
import { StorageSessionService } from '@utils/storage-session.service';
import { StorageService } from '@utils/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [
    SidebarService,
    StorageSessionService,
    AuthService
  ]
})

export class TopbarComponent {
  public environment = environment
  public cart: ICart = {
    products: [],
    subtotal: 0
  }
  public favorites: [] = []
  public currentRoute: string = ''

  constructor(
    private router: Router,
    private siderbarService: SidebarService,
    public storageSessionService: StorageSessionService,
    public authService: AuthService,
    public cartService: CartService,
    public favoritesService: FavoritesService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.storageService.watch('cart').subscribe(() => this.viewItemsInCart())
    this.storageService.watch('favorites').subscribe(() => this.viewItemsInFavorites())

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentRoute = val.url
      }
    })
  }

  openSidebarMainmenu = () => this.siderbarService.openSidebarMainmenu()
  viewItemsInCart = () => this.cart = this.cartService.get()
  removeItemInCart = (sku: string) => this.cartService.remove(sku)
  viewItemsInFavorites = () => this.favorites = this.favoritesService.getlistStorage()
}
