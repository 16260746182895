import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePage } from './profile.page';
import { authGuard } from '@guards/auth.guard';

const routes: Routes = [
  {
    path: 'profile/:tab',
    component: ProfilePage,
    canActivate: [authGuard],
    data: {
      title: "Mi perfil"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ProfileRoutingModule { }
