<form [formGroup]="formLogin">
  <div class="mb-3">
    <label for="formControlEmail" class="form-label mb-1">Correo electrónico</label>
    <div class="input-group has-validation mb-3">
      <input formControlName="email" name="email" type="email" class="form-control bg-body-tertiary py-3"
        id="formControlEmail"
        [ngClass]="{ 'is-invalid': email?.invalid && (email?.dirty || email?.touched) && email?.errors }">

      <ng-container *ngIf="email?.invalid && (email?.dirty || email?.touched)">
        <ng-container *ngIf="email?.errors?.['required']">
          <div class="invalid-feedback message-error px-3">Se requiere un correo electrónico.</div>
        </ng-container>
        <ng-container *ngIf="email?.errors?.['email']">
          <div class="invalid-feedback message-error px-3">Escribe un correo electrónico, válido.</div>
        </ng-container>
        <ng-container *ngIf="email?.errors?.['incorrect']">
          <div class="invalid-feedback message-error px-3">{{email?.errors?.['incorrect']}}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="mb-3">
    <label for="formControlPassword" class="form-label mb-1">Contraseña</label>
    <div class="input-group has-validation mb-0">
      <input formControlName="password" #inputPassword name="password" type="password"
        class="form-control bg-body-tertiary rounded-end-0 py-3" id="formControlPassword"
        [ngClass]="{ 'is-invalid': password?.invalid && (password?.dirty || password?.touched) && password?.errors }">
      <span class="input-group-text rounded-0 rounded-end-1 fs-4 opacity-50" role="button"
        (click)="inputPassword.type=inputPassword.type=='password'?'text':'password'">
        <ng-container *ngIf="inputPassword.type == 'password'">
          <i class="bi bi-eye-fill"></i>
        </ng-container>
        <ng-container *ngIf="inputPassword.type == 'text'">
          <i class="bi bi-eye-slash-fill"></i>
        </ng-container>
      </span>
      <ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
        <ng-container *ngIf="password?.errors?.['required']">
          <div class="invalid-feedback message-error px-3">Escribe una contraseña.</div>
        </ng-container>
        <ng-container *ngIf="password?.errors?.['incorrect']">
          <div class="invalid-feedback message-error px-3">{{password?.errors?.['incorrect']}}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-grid gap-2">
    <button type="button" class="btn btn-primary text-light py-2 w-100" (click)="login()"
      [disabled]="!formLogin.valid || settingsComponent.loadingRequest">{{settingsComponent.btnText}}</button>

    <a [routerLink]="['/register']" class="btn btn-link text-dark text-decoration-none w-100">No tengo cuenta,
      registrarme.</a>
  </div>
</form>