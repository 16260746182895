import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FavoritesService } from '@services/favorites.service';
import { AuthService, IAccessAuth } from '@utils/auth.service';

@Component({
  selector: 'form-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService]
})

export class FormLoginComponent {
  public settingsComponent = {
    btnTextDefault: "Iniciar sesión",
    btnTextLoading: "Iniciando...",
    btnText: "",
    loadingRequest: false
  }
  public formLogin = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private favoritesService: FavoritesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.settingsComponent.btnText = this.settingsComponent.btnTextDefault
  }

  get email() { return this.formLogin.get('email') }
  get password() { return this.formLogin.get('password') }

  login = () => {
    this.settingsComponent.btnText = this.settingsComponent.btnTextLoading
    this.settingsComponent.loadingRequest = true

    let body: IAccessAuth = {
      email: `${this.email?.value}`,
      password: `${this.password?.value}`
    }

    this.authService.login(body).then((response: any) => {
      this.authService.setAuthSession(response.data)

      this.favoritesService.getlistCloud().then((response: any) => this.favoritesService.setlistStorage(response.data.content))

      this.router.navigate(['/'])
    }).catch((error) => {
      this.settingsComponent.btnText = this.settingsComponent.btnTextDefault
      this.settingsComponent.loadingRequest = false

      if (error.error.code === 400 && error.error.data.labels_errors) {
        error.error.data.labels_errors.map((elm: any) => {
          if (elm.label === 'email') {
            this.formLogin.controls['email'].setErrors({ 'incorrect': elm.message });
          }
          if (elm.label === 'password') {
            this.formLogin.controls['password'].setErrors({ 'incorrect': elm.message });
          }
        })
      }
    })
  }
}
