<div class="backdrop" (click)="closeSidebarMainmenu()"></div>

<aside>
  <div class="d-flex flex-column flex-shrink-0 p-3 bg-light h-100" style="width: 280px;">
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none">
      <figure class="m-0 w-75" id="logotype">
        <img src="{{environment.app.assets}}/images/logotype-large.svg" alt="" class="w-100">
      </figure>
    </a>
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <a [routerLink]="['/']" class="nav-link rounded-1" routerLinkActive="active">Inicio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link rounded-1">Categorías</a>
      </li>
      <li class="nav-item">
        <a class="nav-link rounded-1">Ofertas</a>
      </li>
      <li class="nav-item">
        <a class="nav-link rounded-1">Tienda</a>
      </li>
      <li class="nav-item">
        <a class="nav-link rounded-1">Contacto</a>
      </li>
    </ul>
    <hr>
    <button [routerLink]="['/login']" type="button"
      class="btn btn-light border-0 rounded-1 bi bi-person-circle text-start fs-5">
      <span class="ms-2 fs-6">Iniciar sesión</span>
    </button>
    <!-- <div ngbDropdown placement="top-start" class="d-inline-block">
      <button type="button" class="btn btn-light rounded-1" id="" ngbDropdownToggle>
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
        <span class="ms-2">USERNAME</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="" class="dropdown-menu-dark">
        <button ngbDropdownItem>Mis compras</button>
        <button ngbDropdownItem>Facturación</button>
        <button ngbDropdownItem>Mi Perfil</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Cerrar sesión</button>
      </div>
    </div> -->
  </div>
</aside>