import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BudgetService } from '@services/budget.service';
import { FavoritesService } from '@services/favorites.service';
import { StorageService } from '@utils/storage.service';

@Component({
  selector: 'page-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})

export class ProfilePage {
  public active = 'my-account';
  public favorites: [] = []
  public orders: [] = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private favoritesService: FavoritesService,
    private storageService: StorageService,
    private budgetService: BudgetService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.active = routeParams['tab']
      this.onChangeTab()
    });
  }

  onChangeTab = () => {
    switch (this.active) {
      default:
      case 'my-account':
        break;

      case 'favorites':
        this.storageService.watch('favorites').subscribe(() => this.favorites = this.favoritesService.getlistStorage())
        break;

      case 'orders':
        this.budgetService.getList().then(response => this.orders = response)
        break;
    }
  }
}
