import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductViewPage } from './product-view.page';

const routes: Routes = [
  {
    path: 'product/:sku',
    component: ProductViewPage,
    data: {
      title: "Ver producto"
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ProductViewRoutingModule { }
