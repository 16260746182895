import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAddCartComponent } from './add-cart.component';

@NgModule({
  declarations: [
    ButtonAddCartComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ButtonAddCartComponent
  ]
})

export class ButtonAddCartModule { }
