import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

export interface IClient {
  firstname: string,
  lastname: string,
  email: string,
  phone: number,
  country: string,
  state: string,
  municipality: string,
  city: string,
  zipcode: number,
  address: string,
  company: string,
  rfc: string,
  regimen: string,
  cfdi: string,
  csf: object
}

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  constructor(
    private httpService: HttpService
  ) { }

  list = () => this.httpService.get(`/v1/clients`).then((response: any) => response.data)
  getById = (id: number) => this.httpService.get(`/v1/clients/${id}?by=userId`).then((response: any) => response.data)
  update = (body: object, clientGuid: string) => this.httpService.put(`/v1/clients/${clientGuid}`, body).then((response: any) => response.data)
}
