import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselItemsSliderComponent } from './items-slider.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CardProductModule } from '@components/cards/card-product/card-product.module';

@NgModule({
  declarations: [
    CarouselItemsSliderComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    CardProductModule
  ],
  exports: [
    CarouselItemsSliderComponent
  ]
})

export class CarouselItemsSliderModule { }
