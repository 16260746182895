import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})

export class SaleService {
  constructor(
    private httpService: HttpService
  ) { }

  getByGuid = (guid: string) => this.httpService.get(`/v1/sale/${guid}`)
    .then((response: any) => response.data.content)

  getOrder = (guid: string) => this.httpService.get(`/v1/sale/order/sheet/${guid}`)
    .then((response: any) => response.data.content)

  getPayments = (guid: string) => this.httpService.get(`/v1/sale/${guid}/payment`)
    .then((response: any) => response.data.content)
}
