import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePage } from './home.page';
import { HomeRoutingModule } from './home-routing.module';
import { CarouselCoverOneItemModule } from '@components/carousel/cover-one-item/cover-one-item.module';
import { BannerModule } from '@components/banners/banner/banner.module';
import { CardProductModule } from '@components/cards/card-product/card-product.module';
import { CarouselItemsSliderModule } from '@components/carousel/items-slider/items-slider.module';

@NgModule({
  declarations: [
    HomePage
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    CarouselCoverOneItemModule,
    CarouselItemsSliderModule,
    BannerModule,
    CardProductModule
  ]
})

export class HomePageModule { }
