import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormLoginModule } from '@components/forms/login/login.module';

@NgModule({
  declarations: [
    TopbarComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    RouterModule,
    FormLoginModule
  ],
  exports: [
    TopbarComponent
  ]
})

export class TopbarModule { }
