import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselCoverOneItemComponent } from './cover-one-item.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    CarouselCoverOneItemComponent
  ],
  imports: [
    CommonModule,
    CarouselModule
  ],
  exports: [
    CarouselCoverOneItemComponent
  ]
})

export class CarouselCoverOneItemModule { }
