import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import { StorageSessionService } from './storage-session.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  private strBase: string = environment.app.apiUrl

  constructor(
    private fetch: HttpClient,
    private router: Router,
    private storageSessionService: StorageSessionService,
    private toastService: ToastService
  ) { }

  private baseUrl = (endpoint: string): string => this.strBase.concat(endpoint)

  private setHeaders = (withoutToken: boolean = false) => {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')

    if (withoutToken === false) {
      let token: { type: string, token: string } | null = this.storageSessionService.getToken()
      headers = headers.set('Authorization', `${token?.type} ${token?.token}`)
    }

    return { headers };
  }

  private fnError(error: any, reject: any) {
    if (error.status === 401) {
      this.toastService.error('Debes iniciar sesión.')
      this.router.navigate(['/']);
    } else {
      return reject(error)
    }
  }

  get = (endpoint: string, withoutToken: boolean = false) => {
    return new Promise((resolve, reject) => {
      this.fetch.get<any>(this.baseUrl(endpoint), this.setHeaders(withoutToken)).subscribe({
        next: (response) => resolve(response),
        error: error => this.fnError(error, reject)
      })
    });
  }

  post = (endpoint: string, body: any, withoutToken: boolean = false) => {
    return new Promise((resolve, reject) => {
      this.fetch.post<any>(this.baseUrl(endpoint), body, this.setHeaders(withoutToken)).subscribe({
        next: (response) => resolve(response),
        error: error => this.fnError(error, reject)
      })
    });
  }

  put = (endpoint: string, body: any) => {
    return new Promise((resolve, reject) => {
      this.fetch.put<any>(this.baseUrl(endpoint), body, this.setHeaders()).subscribe({
        next: (response) => resolve(response),
        error: error => this.fnError(error, reject)
      })
    });
  }

  delete = (endpoint: string) => {
    return new Promise((resolve, reject) => {
      this.fetch.delete<any>(this.baseUrl(endpoint), this.setHeaders()).subscribe({
        next: (response) => resolve(response),
        error: error => this.fnError(error, reject)
      })
    });
  }
}
