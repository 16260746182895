import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IInputDropdownSettings } from '@components/input-dropdown/input-dropdown.interface';
import { IProduct, ProductsService } from '@services/products.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-product-view',
  templateUrl: './product-view.page.html',
  styleUrls: ['./product-view.page.scss']
})

export class ProductViewPage {
  public sku: string | null = null
  public iteratorQuantity: any[] = []
  public quantity: number = 1
  public inputDropdownSettings: IInputDropdownSettings = {
    type: 'numeric',
    valueDefault: this.quantity
  }
  public listAllProducts: any = []
  public product: IProduct = {
    sku: '',
    name: '',
    price: 0,
    brand: '',
    description: '',
    stock: 0,
    image: null
  }
  public tmpGallery: any[] = []

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productsService: ProductsService
  ) { }

  ngOnInit() {
    for (let index = 1; index <= 99; index++) {
      this.iteratorQuantity.push({ key: index, value: index })
    }

    this.activatedRoute.params.subscribe(routeParams => {
      this.sku = routeParams['sku']

      this.productsService.getAllProducts().then(response => this.listAllProducts = response)
      this.productsService.getProduct(String(this.sku)).then(response => {
        if (response.content.length !== undefined) {
          this.router.navigate(['/'])
        } else {
          this.product = response.content

          let image = (this.product.image != null) ?
            `${environment.app.assets}/uploads/${this.product.image}` : `${environment.app.assets}/images/blank-image.svg`

          this.tmpGallery.push({ id: 1, image })
        }
      })
    });
  }

  setQuantity = (value: any): void => this.quantity = value
}
