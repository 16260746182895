import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPage } from './login.page';
import { LoginRoutingModule } from './login-routing.module';
import { FormLoginModule } from '@components/forms/login/login.module';

@NgModule({
  declarations: [
    LoginPage
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormLoginModule
  ]
})

export class LoginPageModule { }
