<section class="page-content">
  <section class="pt-3 pb-5 position-relative">
    <div class="container">
      <ng-container *ngIf="cart.products.length <= 0">
        <p class="text-center text-muted">El carrito está vacío.</p>
      </ng-container>

      <ng-container *ngIf="cart.products.length > 0">
        <div class="row">
          <div class="col-lg-8 col-12">
            <div class="position-sticky top-0 py-4">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" class="w-100">
                      <p class="m-0 ms-3 ps-5">Producto</p>
                    </th>
                    <th scope="col">Precio</th>
                    <th scope="col" class="px-lg-4">Cantidad</th>
                    <th scope="col" colspan="2">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of cart.products">
                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <figure class="m-0">
                            <ng-container *ngIf="item.product.image !== null">
                              <img src="{{environment.app.assets}}/uploads/{{item.product.image}}" width="50">
                            </ng-container>
                            <ng-container *ngIf="item.product.image === null">
                              <img src="{{environment.app.assets}}/images/blank-image.svg" width="50">
                            </ng-container>
                          </figure>
                          <p class="m-0 ps-3 text-decoration-underline" [routerLink]="['/product', item.product.sku]"
                            role="button">
                            {{item.product.name}}</p>
                        </div>
                      </td>
                      <td class="align-middle text-start">
                        {{item.product.price | currency}}
                      </td>
                      <td class="align-middle text-center">
                        {{item.quantity}}
                      </td>
                      <td class="align-middle text-start">
                        {{item.product.price*item.quantity | currency}}
                      </td>
                      <td class="align-middle">
                        <button type="button" class="btn bi bi-trash3-fill text-danger"
                          (click)="removeItemInCart(item.product.sku)"></button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary fw-semibold py-3 px-5" (click)="removeAll()">Vaciar
                  todo</button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="position-sticky top-0 py-4">
              <div class="border border-2 border-primary rounded-3 p-4">
                <h6 class="text-uppercase fw-bold pb-3 border-bottom">Totales</h6>

                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 pb-3 border-bottom fw-medium">
                  <p class="m-0">Subtotal</p>
                  <p class="m-0">{{cart.subtotal | currency}}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 fw-medium">
                  <p class="m-0">Envío</p>
                  <ng-container *ngIf="shipping <= 0">
                    <p class="m-0">
                      <span class="text-success">Gratis</span>
                      <span class="text-muted text-decoration-line-through ms-1">{{shipping | currency}}</span>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="shipping > 0">
                    <p class="m-0">{{shipping | currency}}</p>
                  </ng-container>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 pb-3 border-bottom fw-medium">
                  <p class="m-0">Impuestos</p>
                  <p class="m-0">{{cart.subtotal * taxes | currency}}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 pb-3 fw-medium">
                  <p class="m-0">Total</p>
                  <p class="m-0 fs-4">{{cart.subtotal + shipping + (cart.subtotal*taxes) | currency: 'MXN'}}</p>
                </div>

                <button [routerLink]="['/checkout']" class="btn btn-primary w-100 fw-semibold py-3">Proceder al
                  pago</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</section>