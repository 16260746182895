import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormMyAccountComponent } from './my-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FormMyAccountComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FormMyAccountComponent
  ]
})

export class FormMyAccountModule { }
