import { Component, Input } from '@angular/core';
import { CartService } from '@services/cart.service';
import { IProduct } from '@services/products.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss'],
  providers: [CartService]
})

export class CardProductComponent {
  public environment = environment
  @Input() product!: IProduct

  constructor() { }
}
