import { Component } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public environment = environment

  constructor(
    private siderbarService: SidebarService
  ) { }

  openSidebarMainmenu = () => this.siderbarService.openSidebarMainmenu()
  closeSidebarMainmenu = () => this.siderbarService.closeSidebarMainmenu()
}
