import { Component, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'carousel-items-slider',
  templateUrl: './items-slider.component.html',
  styleUrls: ['./items-slider.component.scss']
})

export class CarouselItemsSliderComponent {
  @Input() slides: any[] = []

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>'],
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2,
        nav: false,
        dots: true
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    }
  }
}
