<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let image of gallery">
    <ng-template carouselSlide>
      <div class="item">
        <figure class="cover m-0">
          <img src="{{image.image}}" alt="{{image.name}}">
        </figure>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>