<section class="page-content">
  <!-- <carousel-cover-one-item [slides]="coverSlides"></carousel-cover-one-item> -->

  <!-- <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3 mb-lg-0">
          <div class="rounded-1 overflow-hidden h-100" style="min-height: 250px;">
            <banner></banner>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="rounded-1 overflow-hidden mb-3" style="height: 250px;">
            <banner></banner>
          </div>
          <div class="rounded-1 overflow-hidden" style="height: 250px;">
            <banner></banner>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="py-5 bg-body-secondary">
    <div class="container">
      <div class="d-flex align-items-baseline gap-3 mb-4">
        <h3 class="fw-light m-0">Lorem ipsum dolor sit amet.</h3>
        <a href="#" class="text-info text-decoration-none">Ver todo</a>
      </div>

      <carousel-items-slider [slides]="listAllProducts.content"></carousel-items-slider>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="d-flex align-items-baseline gap-3 mb-4">
        <h3 class="fw-light m-0">Lorem ipsum dolor sit amet.</h3>
        <a href="#" class="text-info text-decoration-none">Ver todo</a>
      </div>

      <div class="row">
        <ng-container *ngFor="let item of listAllProducts.content">
          <card-product class="col-sm-6 col-lg-3 mb-4" [product]="item"></card-product>
        </ng-container>
      </div>
    </div>
  </section>
</section>