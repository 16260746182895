import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class ToastService {
  constructor() { }

  success(message: any) {
    return this.toast(message)
  }

  error(message: any) {
    return this.toast(message, "error")
  }

  private toast(message: any, type: any = "success") {
    return Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      customClass: { popup: 'colored-toast' },
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire({
      icon: type,
      title: message
    })
  }
}
