import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private currentStorage = new BehaviorSubject({})

  constructor() {
    // Refresca el valor en otras pestañas
    window.addEventListener('storage', () => this.updateSubject(), false);
  }

  private updateSubject = () => this.currentStorage.next({ ...localStorage })

  watch = (key: string) => this.currentStorage.pipe(map((items: any) => items[key]))

  set = (key: string, value: any): void => { localStorage.setItem(key, value); this.updateSubject() }

  get = (key: string): string | null => localStorage.getItem(key)

  remove = (key: string): void => { localStorage.removeItem(key); this.updateSubject() }

  clear = (): void => { localStorage.clear(); this.updateSubject() }
}
