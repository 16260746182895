import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

export interface IUserSession {
  email: string,
  name: string,
  userId: number,
  username: string
}

@Injectable({
  providedIn: 'root'
})

export class StorageSessionService {
  private USER_KEY: string = 'auth-user';
  private TOKEN_KEY: string = 'auth-token';

  constructor(
    private storageService: StorageService
  ) { }

  public clear = (): void => this.storageService.clear()

  public saveUser = (user: any): void => {
    this.storageService.remove(this.USER_KEY)
    this.storageService.set(this.USER_KEY, JSON.stringify(user))
  }

  public getUser = (): IUserSession => {
    const user = this.storageService.get(this.USER_KEY)

    return (user) ? JSON.parse(user) : {};
  }

  public isLoggedIn = (): boolean => this.storageService.get(this.USER_KEY) ? true : false

  public saveToken = (token: string, type: string): void => {
    this.storageService.remove(this.TOKEN_KEY)
    this.storageService.set(this.TOKEN_KEY, `${type} ${token}`)
  }

  public getToken = (): { type: string, token: string } | null => {
    const token = this.storageService.get(this.TOKEN_KEY)

    return (token) ? { type: token.split(" ")[0], token: token.split(" ")[1] } : null;
  }
}
