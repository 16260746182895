<section class="page-content">
  <section class="pt-3 pb-5 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-12">
          <div ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills position-sticky top-0 py-4"
            orientation="vertical" (activeIdChange)="onChangeTab()">
            <ng-container ngbNavItem="my-account">
              <button class="text-start" ngbNavLink [routerLink]="['/profile/my-account']">Mi cuenta</button>
              <ng-template ngbNavContent>
                <form-my-account />
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem="favorites">
              <button class="text-start" ngbNavLink [routerLink]="['/profile/favorites']">Favoritos</button>
              <ng-template ngbNavContent>
                <div class="row">
                  <ng-container *ngIf="favorites.length <= 0">
                    <p class="m-0 text-center pt-5 opacity-50">No tienes productos agregados a tus favoritos.</p>
                  </ng-container>
                  <ng-container *ngFor="let item of favorites">
                    <card-product class="col-sm-4 mb-4" [product]="item"></card-product>
                  </ng-container>
                </div>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem="orders">
              <button class="text-start" ngbNavLink [routerLink]="['/profile/orders']">Mis pedidos</button>
              <ng-template ngbNavContent>
                <ng-container *ngIf="orders.length <= 0">
                  <p class="m-0 text-center pt-5 opacity-50">No tienes pedidos realizados.</p>
                </ng-container>

                <ng-container *ngFor="let item of orders">
                  <card-product-order [product]="item" />
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem="settings">
              <button class="text-start" ngbNavLink [routerLink]="['/profile/settings']">Configuraciones</button>
              <ng-template ngbNavContent>
                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                  facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                  eu,
                  congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                  ultrices
                  et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus
                  faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio.
                  Orci
                  varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio
                  ac
                  lorem
                  aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar feugiat.
                </p>
              </ng-template>
            </ng-container>
          </div>
        </div>

        <div class="col-lg-9 col-12">
          <div class="position-sticky top-0 py-4" [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </section>
</section>