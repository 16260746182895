import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { SidebarService } from '@components/sidebar/sidebar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public showBasePage = true;
  public title = 'app';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private siderbarService: SidebarService
  ) {
    this.showBasePageFn()
    this.closeSidebarFn()
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe((data: any) => {
        this.titleService.setTitle(`${data.title} - ${environment.app.title}`)
      });
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  showBasePageFn = () => {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url == '/login' || val.url == '/register') {
          this.showBasePage = false
        } else {
          this.showBasePage = true
        }
      }
    })
  }

  closeSidebarFn = () => {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.siderbarService.closeSidebarMainmenu()
      }
    })
  }
}
