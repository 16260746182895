import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})

export class SatService {
  constructor(
    private httpService: HttpService
  ) { }

  getCatalogRegimenFiscal = () => this.httpService.get(`/v1/catalog/sat/regimenfiscal`, true).then((response: any) => response.data)
  getCatalogCFDI = () => this.httpService.get(`/v1/catalog/sat/usocfdi`, true).then((response: any) => response.data)
}
