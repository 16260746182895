import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss']
})
export class CardProductOrderComponent {
  @Input() product: any

  ngOnInit() { }
}
