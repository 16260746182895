import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

export interface IProduct {
  inventoryId?: number,
  sku: string,
  name: string,
  price: number,
  brand: string,
  description: string,
  stock: number,
  image: string | null
}

@Injectable({
  providedIn: 'root'
})

export class ProductsService {
  constructor(
    private httpService: HttpService
  ) { }

  getAllProducts = () => this.httpService.get(`/v1/products/inventory`, true).then((response: any) => response.data)
  getProduct = (sku: string) => this.httpService.get(`/v1/products/inventory/${sku}`, true).then((response: any) => response.data)
}
