import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartPageModule } from '@pages/cart/cart.module';
import { CheckoutPageModule } from '@pages/checkout/checkout.module';
import { LoginPageModule } from '@pages/login/login.module';
import { ProductViewPageModule } from '@pages/product-view/product-view.module';
import { RegisterPageModule } from '@pages/register/register.module';
import { HomePageModule } from '@pages/home/home.module';
import { ProfilePageModule } from '@pages/profile/profile.module';
import { OrderViewPageModule } from '@pages/order-view/order-view.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    HomePageModule,
    LoginPageModule,
    RegisterPageModule,
    ProductViewPageModule,
    CheckoutPageModule,
    CartPageModule,
    ProfilePageModule,
    OrderViewPageModule
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
