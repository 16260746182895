<section class="page-content">
  <section class="pb-5">
    <div class="container">
      <div class="row position-relative">
        <div class="col-lg-6">
          <div class="position-sticky top-0 py-4">
            <carousel-gallery [gallery]="tmpGallery"></carousel-gallery>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="position-sticky top-0 py-4">
            <h4 class="fw-light">{{product.name}}</h4>
            <div class="d-flex align-items-center gap-2 mb-4">
              <h1 class="m-0">{{product.price | currency: 'MXN'}}</h1>
              <h3 class="m-0 text-decoration-line-through opacity-25">{{product.price * 1.334 | currency: 'MXN'}}</h3>
            </div>
            <div class="d-flex align-items-center gap-2 mb-4">
              <p class="m-0">Cant.</p>

              <input-dropdown style="width: 70px;" [settings]="inputDropdownSettings" [iterator]="iteratorQuantity"
                (value)="setQuantity($event)"></input-dropdown>

              <p class="m-0">
                <ng-container *ngIf="product.stock >= 20">
                  <small class="text-success">Disponible {{product.stock}} piezas.</small>
                </ng-container>
                <ng-container *ngIf="product.stock < 20 && product.stock >= 10">
                  <small class="text-warning bi bi-hourglass-split">Solo quedan {{product.stock}} piezas.</small>
                </ng-container>
                <ng-container *ngIf="product.stock < 10">
                  <small class="text-danger bi bi-hourglass-split">Casi agotado.</small>
                </ng-container>
              </p>

              <p class="mb-0">
                <span>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-half"></i>
                  <i class="bi bi-star"></i>
                </span>
                <small class="ms-2">(4.8)</small>
              </p>
            </div>

            <button-add-cart [quantity]="quantity" [item]="product"></button-add-cart>

            <ng-container *ngIf="product.description !== ''">
              <hr>

              <h5>Descripción</h5>
              <div class="content-html">{{product.description}}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5 bg-body-secondary">
    <div class="container">
      <div class="d-flex align-items-baseline gap-3 mb-4">
        <h3 class="fw-light m-0">Productos similares.</h3>
      </div>

      <carousel-items-slider [slides]="listAllProducts.content"></carousel-items-slider>
    </div>
  </section>
</section>