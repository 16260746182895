<form [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <div class="row">
    <div class="col-12">
      <h6 class="text-uppercase fw-bold pb-3 border-bottom mb-4">Información de mi cuenta</h6>
    </div>
    <div class="col-lg-6">
      <div class="mb-3">
        <label for="inputFirstname" class="form-label mb-1">Nombre(s) <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="firstname" name="firstname" type="text" class="form-control bg-body-tertiary py-3"
            id="inputFirstname" required
            [ngClass]="{ 'is-invalid': firstname?.invalid && (firstname?.dirty || firstname?.touched) && firstname?.errors }">
          <ng-container *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)">
            <ng-container *ngIf="firstname?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="mb-3">
        <label for="inputLastname" class="form-label mb-1">Apellidos(s) <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="lastname" name="lastname" type="text" class="form-control bg-body-tertiary py-3"
            id="inputLastname" required
            [ngClass]="{ 'is-invalid': lastname?.invalid && (lastname?.dirty || lastname?.touched) && lastname?.errors }">
          <ng-container *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)">
            <ng-container *ngIf="lastname?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="mb-3">
        <label for="inputEmail" class="form-label mb-1">Correo electrónico <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="email" name="email" type="email" class="form-control bg-body-tertiary py-3"
            id="inputEmail" required
            [ngClass]="{ 'is-invalid': email?.invalid && (email?.dirty || email?.touched) && email?.errors }">
          <ng-container *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            <ng-container *ngIf="email?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="mb-3">
        <label for="inputPhone" class="form-label mb-1">Teléfono <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="phone" name="phone" type="phone" class="form-control bg-body-tertiary py-3"
            id="inputPhone" required
            [ngClass]="{ 'is-invalid': phone?.invalid && (phone?.dirty || phone?.touched) && phone?.errors }">
          <ng-container *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
            <ng-container *ngIf="phone?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-12 mt-5">
      <h6 class="text-uppercase fw-bold pb-3 border-bottom mb-4">Dirección de facturación y envíos</h6>
    </div>
    <div class="col-lg-4">
      <div class="mb-3">
        <label for="inputCountry" class="form-label mb-1">Pais <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <select formControlName="country" name="country" class="form-select bg-body-tertiary py-3" id="inputCountry"
            required
            [ngClass]="{ 'is-invalid': country?.invalid && (country?.dirty || country?.touched) && country?.errors }">
            <ng-container *ngFor="let item of [{code: 'MEX', name: 'México  '}]">
              <option [value]="item.code">{{item.name | uppercase}}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="country?.invalid && (country?.dirty || country?.touched)">
            <ng-container *ngIf="country?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="mb-3">
        <label for="inputState" class="form-label mb-1">Estado <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <select formControlName="state" name="state" class="form-select bg-body-tertiary py-3" id="inputState"
            required [ngClass]="{ 'is-invalid': state?.invalid && (state?.dirty || state?.touched) && state?.errors }">
            <option [value]="null" selected hidden>Selecciona una opción</option>
            <ng-container *ngFor="let item of catalogStates">
              <option [value]="item.code">{{item.name}}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="state?.invalid && (state?.dirty || state?.touched)">
            <ng-container *ngIf="state?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="mb-3">
        <label for="inputMunicipality" class="form-label mb-1">Municipio <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <select formControlName="municipality" name="municipality" class="form-select bg-body-tertiary py-3"
            id="inputMunicipality" required
            [ngClass]="{ 'is-invalid': municipality?.invalid && (municipality?.dirty || municipality?.touched) && municipality?.errors }">
            <option [value]="null" selected hidden>Selecciona una opción</option>
            <ng-container *ngFor="let item of catalogMunicipalities">
              <option [value]="item.idStateMunicipality">{{item.name | uppercase}}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="municipality?.invalid && (municipality?.dirty || municipality?.touched)">
            <ng-container *ngIf="municipality?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="mb-3">
        <label for="inputCity" class="form-label mb-1">Ciudad <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="city" name="city" type="text" class="form-control bg-body-tertiary py-3"
            id="inputCity" required
            [ngClass]="{ 'is-invalid': city?.invalid && (city?.dirty || city?.touched) && city?.errors }">
          <ng-container *ngIf="city?.invalid && (city?.dirty || city?.touched)">
            <ng-container *ngIf="city?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="mb-3">
        <label for="inputZipcode" class="form-label mb-1">Código Postal <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="zipcode" name="zipcode" type="number" class="form-control bg-body-tertiary py-3"
            id="inputZipcode" required
            [ngClass]="{ 'is-invalid': zipcode?.invalid && (zipcode?.dirty || zipcode?.touched) && zipcode?.errors }">
          <ng-container *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)">
            <ng-container *ngIf="zipcode?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="mb-3">
        <label for="inputAddress" class="form-label mb-1">Dirección <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="address" name="address" type="text" class="form-control bg-body-tertiary py-3"
            id="inputAddress" required
            [ngClass]="{ 'is-invalid': address?.invalid && (address?.dirty || address?.touched) && address?.errors }">
          <ng-container *ngIf="address?.invalid && (address?.dirty || address?.touched)">
            <ng-container *ngIf="address?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>


    <div class="col-12 mt-5">
      <h6 class="text-uppercase fw-bold pb-3 border-bottom mb-4">Datos de facturación</h6>
    </div>
    <div class="col-lg-8">
      <div class="mb-3">
        <label for="inputCompany" class="form-label mb-1">Razón social <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="company" name="company" type="text" class="form-control bg-body-tertiary py-3"
            id="inputCompany" required
            [ngClass]="{ 'is-invalid': company?.invalid && (company?.dirty || company?.touched) && company?.errors }">
          <ng-container *ngIf="company?.invalid && (company?.dirty || company?.touched)">
            <ng-container *ngIf="company?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="mb-3">
        <label for="inputRfc" class="form-label mb-1">RFC <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input formControlName="rfc" name="rfc" type="text" class="form-control bg-body-tertiary py-3" id="inputRfc"
            required [ngClass]="{ 'is-invalid': rfc?.invalid && (rfc?.dirty || rfc?.touched) && rfc?.errors }">
          <ng-container *ngIf="rfc?.invalid && (rfc?.dirty || rfc?.touched)">
            <ng-container *ngIf="rfc?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="mb-3">
        <label for="inputRegimen" class="form-label mb-1">Régimen <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <select formControlName="regimen" name="regimen" class="form-select bg-body-tertiary py-3" id="inputRegimen"
            required
            [ngClass]="{ 'is-invalid': regimen?.invalid && (regimen?.dirty || regimen?.touched) && regimen?.errors }">
            <option [value]="null" selected hidden>Selecciona una opción</option>
            <ng-container *ngFor="let item of catalogRegimenFiscal">
              <option value="{{item.id}}">{{item.descripcion | uppercase}}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="regimen?.invalid && (regimen?.dirty || regimen?.touched)">
            <ng-container *ngIf="regimen?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="mb-3">
        <label for="inputCfdi" class="form-label mb-1">Uso de CFDI <span class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <select formControlName="cfdi" name="cfdi" class="form-select bg-body-tertiary py-3" id="inputCfdi" required
            [ngClass]="{ 'is-invalid': cfdi?.invalid && (cfdi?.dirty || cfdi?.touched) && cfdi?.errors }">
            <option [value]="null" selected hidden>Selecciona una opción</option>
            <ng-container *ngFor="let item of catalogCFDI">
              <option value="{{item.id}}">{{item.descripcion | uppercase}}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="cfdi?.invalid && (cfdi?.dirty || cfdi?.touched)">
            <ng-container *ngIf="cfdi?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="mb-3">
        <label for="inputCsf" class="form-label mb-1">Constancia de situación fiscal <span
            class="text-danger">*</span></label>
        <div class="input-group has-validation">
          <input name="csf" type="file" class="form-control bg-body-tertiary py-3" id="inputCsf"
            (change)="onFileSelect($event)" required
            [ngClass]="{ 'is-invalid': csf?.invalid && (csf?.dirty || csf?.touched) && csf?.errors }">
          <ng-container *ngIf="csf?.invalid && (csf?.dirty || csf?.touched)">
            <ng-container *ngIf="csf?.errors?.['required']">
              <div class="invalid-feedback message-error px-3">Campo requerido.</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-flex flex-column">
      <div class="mb-3 mt-1">
        <button type="submit" [disabled]="!form.valid" class="btn btn-primary w-100"
          style="height: 55px;margin-top: 21px;">Guardar</button>
      </div>
    </div>
  </div>
</form>