import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePage } from './profile.page';
import { ProfileRoutingModule } from './profile-routing.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FormMyAccountModule } from '@components/forms/my-account/my-account.module';
import { CardProductModule } from '@components/cards/card-product/card-product.module';
import { CardProductOrderModule } from '@components/cards/product-order/product-order.module';

@NgModule({
  declarations: [
    ProfilePage
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    NgbNavModule,
    FormMyAccountModule,
    CardProductModule,
    CardProductOrderModule
  ]
})

export class ProfilePageModule { }
