import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageSessionService } from '@utils/storage-session.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const jwtHelper = inject(JwtHelperService);
  const storageSession = inject(StorageSessionService);
  const token = storageSession.getToken()

  if (token !== null) { // Existe sesión
    if (['login', 'register'].includes(String(route.url[0]))) { // La url es "/login" o "/ register"
      router.navigate(['/']);
    }

    if (jwtHelper.isTokenExpired(token!.token)) { // El token esta expirado
      router.navigate(['auth']);
    } else {
      return true
    }
  } else {
    if (['login', 'register'].includes(String(route.url[0]))) {
      return true
    } else {
      router.navigate(['login']);
    }
  }

  return false
};
