import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductViewRoutingModule } from './product-view-routing.module';
import { ProductViewPage } from './product-view.page';
import { InputDropdownModule } from '@components/input-dropdown/input-dropdown.module';
import { ButtonAddCartModule } from '@components/buttons/add-cart/add-cart.module';
import { CarouselItemsSliderModule } from '@components/carousel/items-slider/items-slider.module';
import { CarouselGalleryModule } from '@components/carousel/gallery/gallery.module';

@NgModule({
  declarations: [
    ProductViewPage
  ],
  imports: [
    CommonModule,
    ProductViewRoutingModule,
    InputDropdownModule,
    ButtonAddCartModule,
    CarouselItemsSliderModule,
    CarouselGalleryModule
  ],
  exports: [
    ProductViewPage
  ]
})

export class ProductViewPageModule { }
