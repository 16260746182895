import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageSessionService } from '@utils/storage-session.service';
import { Router } from '@angular/router';

export interface IAccessAuth {
  email: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public jwtHelperService = new JwtHelperService();

  constructor(
    private httpService: HttpService,
    private storageSessionService: StorageSessionService,
    private router: Router
  ) { }

  login = (body: IAccessAuth): Promise<any> => {
    return new Promise((resolve, reject) => this.httpService.post(`/v1/login`, body, true)
      .then(response => resolve(response))
      .catch(error => reject(error)))
  }

  logout = (): void => {
    this.storageSessionService.clear()
    this.router.navigate(['/'])
  }

  setAuthSession = (data: any): void => {
    const decodedToken = this.jwtHelperService.decodeToken(data.authorization.token)

    this.storageSessionService.saveToken(data.authorization.token, data.authorization.type)
    this.storageSessionService.saveUser(decodedToken.user)
  }
}
