import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

export interface IBudget {
  clientGuid: string | null | undefined,
  email: string | null | undefined,
  phone: string | null | undefined,
  address: string | null | undefined,
  postalCode: string | null | undefined,
  notes: string | null | undefined,
  discountType: null,
  discountAmount: null,
  taxes: number,
  expiryDate: null,
  paymentMethod: string,
  paymentAccount: number,
  cart: string
}

export interface IBudgetCart {
  quantity: number,
  index: string,
  sku: string,
  description: string,
  price: number,
  total: number
}

@Injectable({
  providedIn: 'root'
})

export class BudgetService {
  constructor(
    private httpService: HttpService
  ) { }

  create = (body: IBudget) => this.httpService.post(`/v1/budget`, body)
    .then((response: any) => response.data)

  getList = () => this.httpService.get(`/v1/budget/mybudgets`)
    .then((response: any) => response.data.content)

  getByGuid = (guid: string) => this.httpService.get(`/v1/budget/${guid}`)
    .then((response: any) => response.data.content)

  getOrder = (guid: string) => this.httpService.get(`/v1/budget/order/sheet/${guid}`)
    .then((response: any) => response.data.content)
}
