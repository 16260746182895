import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})

export class MexicanFederationService {
  constructor(
    private httpService: HttpService
  ) { }

  getCatalogStates = () => this.httpService.get(`/v1/catalog/states`, true).then((response: any) => response.data)
  getCatalogMunicipalities = (state: string) => this.httpService.get(`/v1/catalog/states/municipality/${state}`, true).then((response: any) => response.data)
}
