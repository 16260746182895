<section class="page-content">
  <section class="pt-3 pb-5">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <h5 class="text-uppercase fw-bold pb-3 border-bottom mb-4">Detalles del pedido</h5>

          <div class="card mb-5">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <p class="mb-0 fw-bolder">Estatus</p>
                  <p class="mb-2">
                    <ng-container *ngIf="order.status === 'AVAILABLE'">En espera de
                      aprobación</ng-container>
                    <ng-container *ngIf="order.status === 'CANCELLED'">
                      <span class="text-danger">Cancelada</span></ng-container>
                    <ng-container *ngIf="order.status === 'EXPIRED'">
                      <span class="text-danger">Expirada</span></ng-container>
                    <ng-container *ngIf="order.status === 'SALE'">
                      <span class="text-success">Pedido realizado</span></ng-container>
                  </p>
                  <p class="mb-0 fw-bolder">Folio</p>
                  <p class="mb-2">{{order.foil}}</p>
                  <p class="mb-0 fw-bolder">Método de pago</p>
                  <p class="mb-0">Efectivo.</p>
                </div>
                <div class="col-md-5 mb-3">
                  <p class="mb-0 fw-bolder">Pedido realizado el</p>
                  <p class="mb-2">{{order.dateCreated.formatted}} {{order.dateCreated.time}}</p>
                  <p class="mb-0 fw-bolder">Dirección de envío</p>
                  <p class="mb-0">{{order.client.address}}</p>
                  <p class="mb-0">{{order.client.city}}, {{order.client.state}},
                    {{order.client.municipality}},
                    {{order.client.postalCode}}</p>
                  <p class="mb-0">Teléfono: {{order.client.phone}}</p>
                  <p class="mb-0">Nombre: {{order.client.firstName}} {{order.client.lastName}}</p>
                </div>
                <div class="col-md-3 mb-3">
                  <p class="mb-0 fw-bolder">Resumen del pedido</p>
                  <p class="mb-0 d-flex justify-content-between">
                    <span>Productos:</span> <span>{{order.subtotal | currency}}</span>
                  </p>
                  <p class="mb-0 d-flex justify-content-between"><span>Envío:</span> <span>{{0 |
                      currency}}</span></p>
                  <p class="mb-0 d-flex justify-content-between">
                    <span>Impuestos:</span> <span>{{order.taxes.amount | currency}}</span>
                  </p>
                  <p class="mb-0 d-flex justify-content-between fw-bold">
                    <span>Total:</span> <span>{{order.total | currency}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                </div>
                <div class="col-md-4">
                  <ng-container *ngIf="order.status === 'SALE'">
                    <button class="btn btn-success w-100" disabled>Agregar pago</button>
                  </ng-container>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-light w-100" (click)="openOrderSheet(contentOrderSheet)">
                    Ver orden completa
                  </button>
                </div>
              </div>
            </div>
          </div>

          <h6 class="text-uppercase fw-bold pb-3 border-bottom mb-4">Historial</h6>

          <div class="card border-0">
            <div class="card-body p-0">
              <div class="timeline rounded-1">
                <div class="tl-content tl-content-active" role="button">
                  <div class="tl-header">
                    <span class="tl-marker"></span>
                    <p class="tl-title space-left m-0">Orden realizada</p>
                  </div>
                  <div class="tl-body space-left">
                    <p class="text-muted"><small><em>{{order.dateCreated.formatted}}
                          {{order.dateCreated.time}}</em></small></p>
                    <p class="m-0">Orden realizada a través de la plataforma.</p>
                  </div>
                </div>
                <ng-container *ngIf="order.status === 'AVAILABLE'">
                  <div class="tl-content" role="button">
                    <div class="tl-header">
                      <span class="tl-marker"></span>
                      <p class="tl-title space-left m-0">En espera de aprobación</p>
                    </div>
                    <div class="tl-body space-left">
                      <p class="text-muted"><small><em>{{order.dateCreated.formatted}}
                            {{order.dateCreated.time}}</em></small></p>
                      <p class="m-0"></p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="order.status === 'SALE'">
                  <div class="tl-content tl-content-active" role="button">
                    <div class="tl-header">
                      <span class="tl-marker"></span>
                      <p class="tl-title space-left m-0">Orden aprobada</p>
                    </div>
                    <div class="tl-body space-left">
                      <p class="text-muted"><small><em>{{order.dateCreated.formatted}}
                            {{order.dateCreated.time}}</em></small></p>
                      <p class="m-0">Orden aprobada a través de la plataforma.</p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="order.status === 'EXPIRED' || order.status === 'CANCELLED'">
                  <div class="tl-content tl-content-cancel" role="button">
                    <div class="tl-header">
                      <span class="tl-marker"></span>
                      <ng-container *ngIf="order.status === 'CANCELLED'">
                        <p class="tl-title space-left m-0">Cancelada</p>
                      </ng-container>
                      <ng-container *ngIf="order.status === 'EXPIRED'">
                        <p class="tl-title space-left m-0">Expirada</p>
                      </ng-container>
                    </div>
                    <div class="tl-body space-left">
                      <p class="text-muted"><small><em>{{order.dateCreated.formatted}}
                            {{order.dateCreated.time}}</em></small></p>
                      <ng-container *ngIf="order.status === 'CANCELLED'">
                        <p class="m-0">La orden fue cancelada.</p>
                      </ng-container>
                      <ng-container *ngIf="order.status === 'EXPIRED'">
                        <p class="m-0">La orden fue expirada.</p>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="order.status === 'SALE'">
                  <ng-container *ngIf="payments.length <= 0">
                    <div class="tl-content" role="button">
                      <div class="tl-header">
                        <span class="tl-marker"></span>
                        <p class="tl-title space-left m-0">Procesando pago</p>
                      </div>
                      <div class="tl-body space-left">
                        <p class="text-muted"><small><em>{{order.dateCreated.formatted}}
                              {{order.dateCreated.time}}</em></small></p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="payments.length > 0">
                    <ng-container *ngFor="let item of payments">
                      <div class="tl-content tl-content-active" role="button">
                        <div class="tl-header">
                          <span class="tl-marker"></span>
                          <p class="tl-title space-left m-0">Pago realizado por {{item.amount | currency: 'MXN'}}</p>
                        </div>
                        <div class="tl-body space-left">
                          <p class="text-muted"><small><em>{{item.dateCreated.formatted}}
                                {{item.dateCreated.time}}</em></small></p>
                          <p class="m-0">Al número de cuenta {{item.bankAccount.bank}} - {{item.bankAccount.account}}
                          </p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

<ng-template #contentOrderSheet let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Orden del pedido</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body p-0">
    <div class="bg-light p-3 innerHtml" [innerHtml]="sheet"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary">
      <i class="bi bi-envelope-paper-fill me-1"></i> Enviar por correo electrónico
    </button>
    <button type="button" class="btn btn-primary">
      <i class="bi bi-printer-fill me-1"></i> Imprimir
    </button>
  </div>
</ng-template>