import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BudgetService, IBudget, IBudgetCart } from '@services/budget.service';
import { CartService, ICart } from '@services/cart.service';
import { ClientService } from '@services/client.service';
import { IUserSession, StorageSessionService } from '@utils/storage-session.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss']
})

export class CheckoutPage {
  public userSession: IUserSession = this.storageSessionService.getUser()
  public termsConditions: boolean = false
  public buttonStatus = 'default'
  public cart: ICart = {
    products: [],
    subtotal: 0
  }
  public shipping = 0
  public taxes = 0.16

  public form = this.formBuilder.group({
    clientGuid: ['', [Validators.required]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    company: [''],
    country: ['MEX', [Validators.required]],
    city: ['', [Validators.required]],
    zipcode: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    address: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    email: ['', [Validators.required, Validators.email, Validators.minLength(10)]],
    notes: ['']
  });

  get clientGuid() { return this.form.get('clientGuid') }
  get firstname() { return this.form.get('firstname') }
  get lastname() { return this.form.get('lastname') }
  get company() { return this.form.get('company') }
  get country() { return this.form.get('country') }
  get city() { return this.form.get('city') }
  get zipcode() { return this.form.get('zipcode') }
  get address() { return this.form.get('address') }
  get phone() { return this.form.get('phone') }
  get email() { return this.form.get('email') }
  get notes() { return this.form.get('notes') }

  constructor(
    private router: Router,
    public cartService: CartService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private clientService: ClientService,
    private storageSessionService: StorageSessionService,
    private budgetService: BudgetService
  ) { }

  ngOnInit() {
    this.viewItemsInCart()

    this.clientService.getById(this.userSession.userId)
      .then((response) => response.content)
      .then((client) => {
        this.clientGuid?.setValue(client.guid);
        this.firstname?.setValue(client.firstName);
        this.lastname?.setValue(client.lastName);
        this.company?.setValue(client.company);
        this.city?.setValue(client.city);
        this.zipcode?.setValue(client.postalCode);
        this.address?.setValue(client.address);
        this.phone?.setValue(String(client.phone));
        this.email?.setValue(client.email);

        this.firstname?.disable();
        this.lastname?.disable();
        this.company?.disable();
        this.country?.disable();
        this.city?.disable();
      })
  }

  viewItemsInCart = () => this.cart = this.cartService.get()

  onSubmit = () => {
    this.buttonStatus = 'loading'

    if (!this.termsConditions) {
      this.toastService.error('Debes aceptar los terminos y condiciones del sitio web.')
      this.buttonStatus = 'default'
      return;
    }

    let cart: IBudgetCart[] = []

    this.cart.products.map((response) => {
      cart.push({
        quantity: response.quantity,
        index: this.generateRandomString(8),
        sku: response.product.sku,
        description: response.product.name,
        price: Number(response.product.price),
        total: Number(response.product.price) * response.quantity
      })
    })

    let body: IBudget = {
      clientGuid: this.clientGuid?.value,
      email: this.email?.value,
      phone: this.phone?.value,
      address: this.address?.value,
      postalCode: this.zipcode?.value,
      notes: this.notes?.value,
      discountType: null,
      discountAmount: null,
      taxes: 16,
      expiryDate: null,
      paymentMethod: 'cash',
      paymentAccount: 1,
      cart: JSON.stringify(cart)
    }

    this.budgetService.create(body).then(() => {
      this.buttonStatus = 'success'
      this.cartService.clean()
      this.toastService.success('Pedido realizado')

      setTimeout(() => {
        this.router.navigate(['/profile/orders']);
      }, 1000);
    }).catch(error => {
      this.toastService.error(error.error.text)
      this.buttonStatus = 'default'
    })
  }

  generateRandomString = (num: number) => {
    let result1 = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < num; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
  }
}
