import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardProductOrderComponent } from './product-order.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CardProductOrderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbTooltipModule
  ],
  exports: [
    CardProductOrderComponent
  ]
})
export class CardProductOrderModule { }
