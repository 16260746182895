import { Component, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BudgetService } from '@services/budget.service';
import { SaleService } from '@services/sale.service';

@Component({
  selector: 'page-order-view',
  templateUrl: './order-view.page.html',
  styleUrls: ['./order-view.page.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OrderViewPage {
  public guid!: string
  public order!: any
  public sheet!: string
  public payments!: any[]

  constructor(
    private activatedRoute: ActivatedRoute,
    private budgetService: BudgetService,
    private saleService: SaleService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.guid = routeParams['guid']

      this.budgetService.getByGuid(this.guid).then(response => {
        if (response.status === 'SALE') {
          this.order = response
          this.saleService.getOrder(this.guid).then(response => this.sheet = response)
          this.saleService.getPayments(this.guid).then(response => this.payments = response)
        } else {
          this.order = response
          this.budgetService.getOrder(this.guid).then(response => this.sheet = response)
        }
      })
    });
  }

  openOrderSheet = (content: TemplateRef<any>) => this.modalService.open(content, { size: 'xl' })
}
