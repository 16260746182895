import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '@services/client.service';
import { MexicanFederationService } from '@services/mexican-federation.service';
import { SatService } from '@services/sat.service';
import { StorageSessionService, IUserSession } from '@utils/storage-session.service';

@Component({
  selector: 'form-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  providers: [SatService]
})

export class FormMyAccountComponent {
  public userSession: IUserSession = this.storageSessionService.getUser()
  public catalogStates: any[] = []
  public catalogMunicipalities: any[] = []
  public catalogRegimenFiscal: any[] = []
  public catalogCFDI: any[] = []
  public form = this.formBuilder.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    country: ['MEX', [Validators.required]],
    state: [null, [Validators.required]],
    municipality: [null, [Validators.required]],
    city: ['', [Validators.required]],
    zipcode: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    address: ['', [Validators.required]],
    company: ['', [Validators.required]],
    rfc: ['', [Validators.required]],
    regimen: [null, [Validators.required]],
    cfdi: [null, [Validators.required]],
    csf: ['', [Validators.required]],
  });

  get firstname() { return this.form.get('firstname') }
  get lastname() { return this.form.get('lastname') }
  get email() { return this.form.get('email') }
  get phone() { return this.form.get('phone') }
  get country() { return this.form.get('country') }
  get state() { return this.form.get('state') }
  get municipality() { return this.form.get('municipality') }
  get city() { return this.form.get('city') }
  get zipcode() { return this.form.get('zipcode') }
  get address() { return this.form.get('address') }
  get company() { return this.form.get('company') }
  get rfc() { return this.form.get('rfc') }
  get regimen() { return this.form.get('regimen') }
  get cfdi() { return this.form.get('cfdi') }
  get csf() { return this.form.get('csf') }

  constructor(
    private formBuilder: FormBuilder,
    private satService: SatService,
    private mexicanFederationService: MexicanFederationService,
    private clientService: ClientService,
    private storageSessionService: StorageSessionService
  ) { }

  ngOnInit() {
    this.mexicanFederationService.getCatalogStates().then(response => this.catalogStates = response.content)
    this.satService.getCatalogRegimenFiscal().then(response => this.catalogRegimenFiscal = response.content)
    this.satService.getCatalogCFDI().then(response => this.catalogCFDI = response.content)

    this.state?.valueChanges.subscribe(value => {
      this.catalogMunicipalities = []
      this.mexicanFederationService.getCatalogMunicipalities(String(value)).then(response => this.catalogMunicipalities = response.content)
      this.form.patchValue({ municipality: null })
    })

    this.clientService.getById(this.userSession.userId).then((response) => {
      let client = response.content

      this.firstname?.setValue(client.firstName);
      this.lastname?.setValue(client.lastName);
      this.email?.setValue(client.email);
      this.phone?.setValue(String(client.phone));
      // this.country?.setValue(client.country);
      this.state?.setValue(client.stateCode);
      this.municipality?.setValue(client.municipalityId);
      this.city?.setValue(client.city);
      this.zipcode?.setValue(client.postalCode);
      this.address?.setValue(client.address);
      this.company?.setValue(client.company);
      this.rfc?.setValue(client.rfc);
      // this.regimen?.setValue(client.regimen);
      // this.cfdi?.setValue(client.cfdi);
      // this.csf?.setValue(client.csf);
    })
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.csf?.setValue(file);
    }
  }

  onSubmit = () => {
    const body: Object = this.form.value

    const myFormData = new FormData();
    for (const [key, value] of Object.entries(body)) {
      myFormData.append(key, value);
    }
  }
}
