<div class="card mb-4">
  <div class="card-header">
    <div class="row">
      <div class="col-4 d-flex align-items-center">
        <p class="mb-0">
          <ng-container *ngIf="product.status === 'AVAILABLE'">En espera</ng-container>
          <ng-container *ngIf="product.status === 'CANCELLED'">
            <span class="text-danger">Cancelada</span></ng-container>
          <ng-container *ngIf="product.status === 'EXPIRED'">
            <span class="text-danger">Expirada</span></ng-container>
          <ng-container *ngIf="product.status === 'SALE'">
            <span class="text-success">Pedido realizado</span></ng-container>
        </p>
      </div>
      <div class="col-4">
        <ng-template #tipSendAddressContent>
          <h6 class="m-0">{{product.client.firstName}} {{product.client.lastName}}</h6>
          <p class="m-0">{{product.client.address}}</p>
          <p class="m-0">{{product.client.city}}, {{product.client.state}}, {{product.client.postalCode}}</p>
          <p class="m-0">Teléfono: {{product.client.phone}}</p>
        </ng-template>
        <button type="button" class="btn btn-link p-0" [ngbTooltip]="tipSendAddressContent" placement="bottom"
          triggers="click:blur">Enviar a: {{product.client.firstName}} {{product.client.lastName}} <i
            class="bi bi-chevron-down"></i></button>
      </div>
      <div class="col-4 d-flex align-items-center">
        <p class="mb-0">Folio: <strong>{{product.foil}}</strong></p>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-8">
        <ng-container *ngFor="let item of product.cart">
          <div class="card mb-3 border-0">
            <div class="card-body p-0">
              <p class="card-text mb-0">{{item.description}}</p>
              <p class="card-text mb-2">
                <small class="text-body-secondary">Cantidad: {{item.quantity}}</small>
                <small class="text-body-secondary ms-4">Precio: {{item.price | currency: 'MXN'}}</small>
              </p>
              <button type="button" class="btn btn-warning me-2 opacity-25" disabled>Comprar nuevamente</button>
              <button type="button" class="btn btn-light ms-2" [routerLink]="['/product', item.sku]">Ver
                artículo</button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-md-4">
        <button [routerLink]="['/order', product.guid]" class="btn btn-light w-100 mb-2">Ver detalles del
          pedido</button>

        <ng-container *ngIf="product.status === 'AVAILABLE'">
          <button class="btn btn-light w-100 mb-2" disabled>Escribir una nota</button>
          <button class="btn btn-danger w-100 mb-2" disabled>Cancelar</button>
        </ng-container>

        <ng-container *ngIf="product.status === 'SALE'">
          <button class="btn btn-light w-100 mb-2" disabled>Adjuntar pago</button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card-footer text-body-secondary">
    <div class="row">
      <div class="col-4">
        <ng-template #tipDateCreatedContent>{{product.dateCreated.date}} {{product.dateCreated.time}}</ng-template>
        <button type="button" class="btn btn-link text-decoration-none p-0" [ngbTooltip]="tipDateCreatedContent"
          placement="top" triggers="click:blur">
          Realizado: <strong>{{product.dateCreated.formatted}}</strong>
        </button>
      </div>
      <div class="col-4">
        <p class="mb-0 d-flex align-items-center">
          Pago en
          <ng-container *ngIf="product.payment.method === 'cash'">Efectivo</ng-container>
        </p>
      </div>
      <div class="col-4">
        <ng-template #tipTaxesContent>
          <p class="m-0">Subtotal - {{product.subtotal | currency: 'MXN'}}</p>
          <p class="m-0">I.V.A - {{product.taxes.amount| currency: 'MXN'}} ({{product.taxes.percentage}}%)</p>
        </ng-template>
        <button type="button" class="btn btn-link text-decoration-none p-0" [ngbTooltip]="tipTaxesContent"
          placement="top" triggers="click:blur">
          Total: <strong>{{product.total | currency: 'MXN'}}</strong>
        </button>
      </div>
    </div>
  </div>
</div>