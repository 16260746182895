import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from './login.page';
import { authGuard } from '@guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
    canActivate: [authGuard],
    data: {
      title: "Iniciar sesión"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class LoginRoutingModule { }
