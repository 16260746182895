<section class="app">
  <ng-container *ngIf="showBasePage == true">
    <topbar></topbar>
    <sidebar></sidebar>
  </ng-container>

  <main class="wrapper">
    <router-outlet></router-outlet>
  </main>

  <ng-container *ngIf="showBasePage == true">
    <footer></footer>
  </ng-container>
</section>