import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {
  constructor() { }

  openSidebarMainmenu = () => document.body.classList.add('open-sidebar-mainmenu')
  closeSidebarMainmenu = () => document.body.classList.remove('open-sidebar-mainmenu')
}
