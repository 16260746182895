import { Injectable } from '@angular/core';
import { StorageService } from '@utils/storage.service';
import { ToastService } from '@utils/toast.service';

export interface ICart {
  products: any[],
  subtotal: number
}

@Injectable({
  providedIn: 'root'
})

export class CartService {
  private CART_KEY: string = 'cart';

  constructor(
    private storage: StorageService,
    private toastService: ToastService
  ) { }

  add = (item: any, quantity: number): void => {
    let cart: any[] = []
    let cartStorage = this.storage.get(this.CART_KEY)

    cart = (cartStorage !== null) ? JSON.parse(cartStorage) : []

    cart.push({ quantity, item })

    this.storage.set(this.CART_KEY, JSON.stringify(cart))
    this.toastService.success(`"${item.name}" se agregó a tu carrito.`)
  }

  get = (): ICart => {
    let result: any[] = []
    let subtotal: number = 0
    let cart = JSON.parse(String(this.storage.get(this.CART_KEY)))
    cart = (cart !== null) ? cart : []

    cart.map((response: any) => {
      let index = result.findIndex((elm) => elm.product.sku === response.item.sku)

      if (index === -1) {
        result.push({
          'quantity': response.quantity,
          'product': response.item,
        })
      } else {
        result[index].quantity += Number(response.quantity)
      }

      subtotal += (Number(response.item.price) * Number(response.quantity))
    })

    return { products: result, subtotal }
  }

  remove = (sku: any): void => {
    let cart = JSON.parse(String(this.storage.get(this.CART_KEY)))
    let result: any[] = []

    cart.map((response: any) => {
      if (response.item.sku !== sku) {
        result.push(response)
      }
    })

    this.storage.set(this.CART_KEY, JSON.stringify(result))
  }

  clean = (): void => this.storage.remove(this.CART_KEY)
}
