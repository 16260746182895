import { Component } from '@angular/core';
import { CartService, ICart } from '@services/cart.service';
import { StorageService } from '@utils/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss']
})

export class CartPage {
  public environment = environment
  public cart: ICart = {
    products: [],
    subtotal: 0
  }
  public shipping = 0
  public taxes = 0.16

  constructor(
    public cartService: CartService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.storageService.watch('cart').subscribe(() => this.viewItemsInCart())
  }

  viewItemsInCart = () => this.cart = this.cartService.get()
  removeItemInCart = (sku: string) => this.cartService.remove(sku)
  removeAll = () => this.cartService.clean()
}
