import { Component, Input } from '@angular/core';
import { CartService } from '@services/cart.service';

export interface IButtonAddCartSettings {
  buttonColor?: string | undefined
}

@Component({
  selector: 'button-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.scss'],
  providers: [CartService]
})

export class ButtonAddCartComponent {
  @Input() settings!: IButtonAddCartSettings
  @Input() quantity?: number
  @Input() item: any
  @Input() text = 'Agregar al carrito'
  public buttonStatus = 'default'
  public buttonColor: string = ''

  constructor(
    public cartService: CartService
  ) { }

  ngOnInit() {
    this.buttonColor = (this.settings?.buttonColor) ? this.settings.buttonColor : 'btn-primary'
  }

  addToCart = (product: object) => {
    let quantity = (this.quantity !== undefined) ? this.quantity : 1

    this.buttonStatus = 'loading'
    this.cartService.add(product, quantity)

    setTimeout(() => {
      this.buttonStatus = 'success'

      setTimeout(() => {
        this.buttonStatus = 'default'
      }, 1000);
    }, 1000);
  }
}
