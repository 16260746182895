<section class="page-content">
  <section class="pt-3 pb-5 position-relative">
    <div class="container">
      <ng-container *ngIf="cart.products.length <= 0">
        <p class="text-center text-muted">No hay articulos para realizar el pago.</p>
      </ng-container>

      <ng-container *ngIf="cart.products.length > 0">
        <div class="row">
          <div class="col-lg-8 col-12">
            <form [formGroup]="form" class="position-sticky top-0 py-4">
              <div class="row">
                <div class="col-12">
                  <h6 class="text-uppercase fw-bold pb-3 border-bottom mb-4">Detalles de la compra</h6>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="inputFirstname" class="form-label mb-1">Nombre(s) <span
                        class="text-danger">*</span></label>
                    <div class="input-group has-validation">
                      <input formControlName="firstname" name="firstname" type="text"
                        class="form-control bg-body-tertiary py-3" id="inputFirstname" required
                        [ngClass]="{ 'is-invalid': firstname?.invalid && (firstname?.dirty || firstname?.touched) && firstname?.errors }">
                      <ng-container *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)">
                        <ng-container *ngIf="firstname?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="inputLastname" class="form-label mb-1">Apellidos(s) <span
                        class="text-danger">*</span></label>
                    <div class="input-group has-validation">
                      <input formControlName="lastname" name="lastname" type="text"
                        class="form-control bg-body-tertiary py-3" id="inputLastname" required
                        [ngClass]="{ 'is-invalid': lastname?.invalid && (lastname?.dirty || lastname?.touched) && lastname?.errors }">
                      <ng-container *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)">
                        <ng-container *ngIf="lastname?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="inputCompany" class="form-label mb-1">Razón social</label>
                    <div class="input-group has-validation">
                      <input formControlName="company" name="company" type="text"
                        class="form-control bg-body-tertiary py-3" id="inputCompany">
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="inputCountry" class="form-label mb-1">Pais</label>
                    <div class="input-group has-validation">
                      <select formControlName="country" name="country" class="form-select bg-body-tertiary py-3"
                        id="inputCountry" required
                        [ngClass]="{ 'is-invalid': country?.invalid && (country?.dirty || country?.touched) && country?.errors }">
                        <option value="MEX" selected>México</option>
                      </select>
                      <ng-container *ngIf="country?.invalid && (country?.dirty || country?.touched)">
                        <ng-container *ngIf="country?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="inputCity" class="form-label mb-1">Ciudad</label>
                    <div class="input-group has-validation">
                      <input formControlName="city" name="city" type="text" class="form-control bg-body-tertiary py-3"
                        id="inputCity" required
                        [ngClass]="{ 'is-invalid': city?.invalid && (city?.dirty || city?.touched) && city?.errors }">
                      <ng-container *ngIf="city?.invalid && (city?.dirty || city?.touched)">
                        <ng-container *ngIf="city?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="inputZipcode" class="form-label mb-1">Código Postal</label>
                    <div class="input-group has-validation">
                      <input formControlName="zipcode" name="zipcode" type="number"
                        class="form-control bg-body-tertiary py-3" id="inputZipcode" required
                        [ngClass]="{ 'is-invalid': zipcode?.invalid && (zipcode?.dirty || zipcode?.touched) && zipcode?.errors }">
                      <ng-container *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)">
                        <ng-container *ngIf="zipcode?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="inputAddress" class="form-label mb-1">Dirección</label>
                    <div class="input-group has-validation">
                      <input formControlName="address" name="address" type="text"
                        class="form-control bg-body-tertiary py-3" id="inputAddress" required
                        [ngClass]="{ 'is-invalid': address?.invalid && (address?.dirty || address?.touched) && address?.errors }">
                      <ng-container *ngIf="address?.invalid && (address?.dirty || address?.touched)">
                        <ng-container *ngIf="address?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="inputPhone" class="form-label mb-1">Teléfono</label>
                    <div class="input-group has-validation">
                      <input formControlName="phone" name="phone" type="phone"
                        class="form-control bg-body-tertiary py-3" id="inputPhone" required
                        [ngClass]="{ 'is-invalid': phone?.invalid && (phone?.dirty || phone?.touched) && phone?.errors }">
                      <ng-container *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
                        <ng-container *ngIf="phone?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="inputEmail" class="form-label mb-1">Correo electrónico</label>
                    <div class="input-group has-validation">
                      <input formControlName="email" name="email" type="email"
                        class="form-control bg-body-tertiary py-3" id="inputEmail" required
                        [ngClass]="{ 'is-invalid': email?.invalid && (email?.dirty || email?.touched) && email?.errors }">
                      <ng-container *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <ng-container *ngIf="email?.errors?.['required']">
                          <div class="invalid-feedback message-error px-3">Campo requerido.</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div>
                    <label for="inputNotes" class="form-label mb-1">Notas</label>
                    <div class="input-group has-validation">
                      <textarea formControlName="notes" name="notes" class="form-control bg-body-tertiary py-3"
                        id="inputNotes" rows="3"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-4 col-12">
            <div class="position-sticky top-0 py-4">
              <div class="border border-2 border-primary rounded-3 p-4">
                <h6 class="text-uppercase fw-bold pb-3 border-bottom">Orden de servicio</h6>

                <div class="d-flex justify-content-between mt-3 pb-3 border-bottom fw-medium">
                  <p class="m-0">Producto</p>
                  <p class="m-0">Subtotal</p>
                </div>

                <div class="pt-3 pb-2 border-bottom">
                  <ng-container *ngFor="let item of cart.products">
                    <div class="d-flex gap-2 w-100 justify-content-between align-items-center pb-2">
                      <p class="m-0">{{item.product.name}} <span class="me-1 fw-bold">x{{item.quantity}}</span></p>
                      <p class="m-0">{{item.product.price*item.quantity | currency}}</p>
                    </div>
                  </ng-container>
                </div>

                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 pb-3 border-bottom fw-medium">
                  <p class="m-0">Subtotal</p>
                  <p class="m-0">{{cart.subtotal | currency}}</p>
                </div>

                <div class="d-flex align-items-center justify-content-between mt-3 fw-medium">
                  <p class="m-0">Envío</p>
                  <ng-container *ngIf="shipping <= 0">
                    <p class="m-0">
                      <span class="text-success">Gratis</span>
                      <span class="text-muted text-decoration-line-through ms-1">{{shipping | currency}}</span>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="shipping > 0">
                    <p class="m-0">{{shipping | currency}}</p>
                  </ng-container>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 pb-3 border-bottom fw-medium">
                  <p class="m-0">Impuestos</p>
                  <p class="m-0">{{cart.subtotal * taxes | currency}}</p>
                </div>

                <div class="d-flex align-items-center justify-content-between mt-3 mb-3 pb-3 border-bottom fw-medium">
                  <p class="m-0">Total</p>
                  <p class="m-0 fs-4">{{cart.subtotal + shipping + (cart.subtotal*taxes) | currency: 'MXN'}}</p>
                </div>

                <p>Sus datos personales se utilizarán para tramitar su pedido, apoyar su experiencia en este sitio web y
                  para otros fines descritos en nuestra <a class="text-info" role="button">política de privacidad</a>.
                </p>

                <div class="form-check d-flex align-items-center gap-2 mb-4">
                  <input [(ngModel)]="termsConditions" name="termsConditions" class="form-check-input" type="checkbox"
                    value="accept" id="inputTermsConditions" required>
                  <label class="form-check-label user-select-none" for="inputTermsConditions" role="button">
                    He leído y acepto los <a class="text-info">términos y condiciones del sitio web</a>.
                  </label>
                </div>

                <button (click)="onSubmit()" type="button" class="btn w-100 fw-semibold py-3"
                  [ngClass]="buttonStatus === 'success' ? 'btn-success' :'btn-success'"
                  [disabled]="!form.valid || buttonStatus !== 'default'">
                  <ng-container *ngIf="buttonStatus === 'default'">Realizar pedido</ng-container>
                  <ng-container *ngIf="buttonStatus === 'loading'">Completando...</ng-container>
                  <ng-container *ngIf="buttonStatus === 'success'">Pedido realizado</ng-container>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</section>