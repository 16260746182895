import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardProductComponent } from './card-product.component';
import { RouterModule } from '@angular/router';
import { ButtonAddCartModule } from '@components/buttons/add-cart/add-cart.module';
import { ButtonAddFavoriteModule } from '@components/buttons/add-favorite/add-favorite.module';

@NgModule({
  declarations: [
    CardProductComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonAddCartModule,
    ButtonAddFavoriteModule
  ],
  exports: [
    CardProductComponent
  ]
})

export class CardProductModule { }
