<section class="topbar bg-primary">
  <header id="toolbar" class="py-2">
    <div class="container d-flex">
      <div class="d-none d-lg-block col-lg-6">
        <ul class="nav nav-pills gap-1 m-0">
          <li class="nav-item">
            <button type="button" class="nav-link py-1 px-2">
              Acerca de
            </button>
          </li>
          <li class="nav-item">
            <button [routerLink]="['/profile/my-account']" type="button" class="nav-link py-1 px-2">
              Mi cuenta
            </button>
          </li>
          <li class="nav-item">
            <button [routerLink]="['/profile/favorites']" type="button" class="nav-link py-1 px-2">
              Favoritos
            </button>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-6">
        <ul class="nav nav-pills gap-1 justify-content-center justify-content-lg-end m-0">
          <li class="nav-item">
            <button [routerLink]="['/profile/orders']" type="button" class="nav-link py-1 px-2">
              Mis pedidos
            </button>
          </li>
          <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
            <button type="button" class="nav-link py-1 px-2 bi bi-translate" tabindex="0" ngbDropdownToggle>
              Español
            </button>
            <div ngbDropdownMenu aria-labelledby="">
              <button ngbDropdownItem>Español</button>
            </div>
          </li>
          <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
            <button type="button" class="nav-link py-1 px-2 bi bi-currency-dollar" tabindex="0" ngbDropdownToggle>
              MXN
            </button>
            <div ngbDropdownMenu aria-labelledby="">
              <button ngbDropdownItem>MXN - Peso Mexicano</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <header id="searchbar">
    <div class="container d-flex justify-content-between gap-2">
      <figure id="logotype" [routerLink]="['/']" class="pe-3 m-0" role="button">
        <img class="w-100 h-100" src="{{environment.app.assets}}/images/logotype-large-white.svg" alt="">
      </figure>

      <div class="search w-100 d-none d-xl-flex align-items-stretch rounded-1 overflow-hidden bg-light"
        data-bs-theme="light">
        <select class="form-select bg-transparent border-0 rounded-0 w-max-content shadow-none">
          <option selected>Todas las categorías</option>
          <option value="1">Categoría 1</option>
          <option value="2">Categoría 2</option>
          <option value="3">Categoría 3</option>
        </select>
        <input id="search" type="search" class="form-control bg-transparent border-0 rounded-0"
          placeholder="Buscar producto." autocomplete="off">
        <button class="btn btn-secondary rounded-0 px-3 fw-bold">Buscar</button>
      </div>

      <div class="addons w-min-fit-content">
        <ul class="nav nav-pills gap-1 d-flex justify-content-center justify-content-lg-end m-0">
          <li class="nav-item addon addon-signin" display="dynamic" placement="bottom-end" ngbDropdown>
            <button type="button" class="nav-link d-flex py-1 px-2 text-bg-primary" ngbDropdownToggle>
              <span class="addon-icon bi bi-person"></span>
              <span class="addon-text ms-2">
                <ng-container *ngIf="!storageSessionService.isLoggedIn()">
                  <small class="d-block">Ingresar a</small>
                </ng-container>
                <ng-container *ngIf="storageSessionService.isLoggedIn()">
                  <small class="d-block">Datos de</small>
                </ng-container>
                Mi cuenta
              </span>
            </button>
            <div ngbDropdownMenu class="dropdown-menu-end"
              [ngClass]="!storageSessionService.isLoggedIn() ? 'px-4 py-3' : 'minWidth150'">
              <ng-container *ngIf="!storageSessionService.isLoggedIn()">
                <form-login></form-login>
              </ng-container>

              <ng-container *ngIf="storageSessionService.isLoggedIn()">
                <button [routerLink]="['/profile/orders']" ngbDropdownItem>Mis pedidos</button>
                <button [routerLink]="['/profile/my-account']" ngbDropdownItem>Mi cuenta</button>
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="authService.logout()">Cerrar Sesión</button>
              </ng-container>
            </div>
          </li>

          <li class="nav-item pe-2 addon">
            <button [routerLink]="['/profile/favorites']" type="button"
              class="nav-link d-flex py-1 px-2 text-bg-primary d-none d-lg-block">
              <span class="addon-icon position-relative bi bi-heart">
                <ng-container *ngIf="favorites.length > 0">
                  <span class="position-absolute start-100 translate-middle badge rounded-pill text-bg-secondary">
                    {{favorites.length}} <span class="visually-hidden">Nuevos Favoritos</span>
                  </span>
                </ng-container>
              </span>
            </button>
          </li>

          <li class="nav-item addon addon-cart" display="dynamic" placement="bottom-end" ngbDropdown>
            <button type="button" class="nav-link d-flex py-1 px-2 text-bg-primary" ngbDropdownToggle
              [disabled]="currentRoute === '/checkout' || currentRoute === '/cart'">
              <span class="addon-icon position-relative bi bi-cart">
                <span class="position-absolute start-100 translate-middle badge rounded-pill text-bg-secondary">
                  {{cart.products.length}} <span class="visually-hidden">Agregados al carrito</span>
                </span>
              </span>
              <span class="addon-text">
                <small class="d-block">Total</small>
                {{cart.subtotal | currency}}
              </span>
            </button>
            <div ngbDropdownMenu class="dropdown-menu-end px-4 py-3">
              <ng-container *ngIf="cart.products.length <= 0">
                <div class="text-center opacity-50 py-4">
                  <i class="display-3 bi bi-cart"></i>
                  <p class="fw-bold m-0">Sin productos</p>
                </div>
              </ng-container>
              <ng-container *ngIf="cart.products.length > 0">
                <div class="overflow-y-auto" style="max-height: 200px;">
                  <ng-container *ngFor="let item of cart.products">
                    <div class="d-flex gap-2 pb-2 border-bottom mb-2">
                      <figure class="m-0">
                        <ng-container *ngIf="item.product.image != null">
                          <img src="{{environment.app.assets}}/uploads/{{item.product.image}}" width="50">
                        </ng-container>
                        <ng-container *ngIf="item.product.image === null">
                          <img src="{{environment.app.assets}}/images/blank-image.svg" width="50">
                        </ng-container>
                      </figure>
                      <div class="w-100 position-relative">
                        <p class="m-0 fw-semibold text-decoration-underline text-truncate"
                          [routerLink]="['/product', item.product.sku]" role="button" title="{{item.product.name}}">
                          {{item.product.name.slice(0, 25)}}...
                        </p>
                        <p class="m-0">
                          <span class="me-1 opacity-75">{{item.quantity}}x</span>
                          <span class="fw-medium">{{item.product.price | currency}}</span>
                        </p>
                      </div>
                      <button type="button" class="btn bi bi-x" (click)="removeItemInCart(item.product.sku)"></button>
                    </div>
                  </ng-container>
                </div>

                <div class="d-flex align-items-center justify-content-between fs-5 my-3">
                  <p class="m-0">Subtotal:</p>
                  <p class="m-0 fw-medium">{{cart.subtotal | currency: 'MXN'}}</p>
                </div>

                <button type="button" [routerLink]="['/cart']" class="btn btn-light w-100 mb-1" ngbDropdownToggle>Ver
                  Carrito</button>
                <button type="button" [routerLink]="['/checkout']" ngbDropdownToggle
                  class="btn btn-primary w-100">Proceder al pago</button>
              </ng-container>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <header id="navbar">
    <div class="container">
      <ul class="nav nav-pills position-relative m-0">
        <li class="nav-item btn-group text-bg-secondary rounded-bottom-0">
          <button type="button" class="btn border-0 fs-4 bi bi-list" (click)="openSidebarMainmenu()"></button>
          <button [routerLink]="['/']" type="button" class="nav-link text-bg-secondary ps-2 pe-4 h-100">Inicio</button>
        </li>
        <li class="nav-item megamenu" ngbDropdown>
          <button type="button" class="nav-link h-100" ngbDropdownToggle>Categorías</button>
          <div ngbDropdownMenu class="rounded-top-0 py-0">
            <ul class="submenu list-unstyled d-lg-flex p-3">
              <li class="menu-item flex-grow-1 mb-3">
                <h6 class="title-list mb-1">Shop List</h6>

                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                </ul>
              </li>
              <li class="menu-item flex-grow-1 mb-3">
                <h6 class="title-list mb-1">Shop List</h6>

                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                </ul>
              </li>
              <li class="menu-item flex-grow-1 w-lg-50">
                <div class="row gx-2">
                  <div class="col-lg-12 mb-2">
                    <div class="w-100 bg-secondary-subtle rounded-1" style="height: 200px;"></div>
                  </div>
                  <div class="col-lg-6 mb-2 mb-lg-0">
                    <div class="w-100 bg-secondary-subtle rounded-1" style="height: 100px;"></div>
                  </div>
                  <div class="col-lg-6">
                    <div class="w-100 bg-secondary-subtle rounded-1" style="height: 100px;"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item" ngbDropdown>
          <button type="button" class="nav-link h-100" ngbDropdownToggle>Menu</button>
          <div ngbDropdownMenu class="rounded-top-0 py-0">
            <ul class="submenu list-unstyled p-3">
              <li class="menu-item flex-grow-1 mb-3">
                <h6 class="title-list mb-1">Shop List</h6>

                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                </ul>
              </li>
              <li class="menu-item flex-grow-1">
                <h6 class="title-list mb-1">Shop List</h6>

                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                  <li>
                    <a [routerLink]="['/']" class="btn menu-link text-start w-100 px-0">Shop default</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item d-none d-lg-block">
          <button type="button" class="nav-link h-100">Ofertas</button>
        </li>
        <li class="nav-item d-none d-lg-block">
          <button type="button" class="nav-link h-100">Tienda</button>
        </li>
        <li class="nav-item d-none d-lg-block">
          <button type="button" class="nav-link h-100">Páginas</button>
        </li>
        <li class="nav-item d-none d-lg-block">
          <button type="button" class="nav-link h-100">Contacto</button>
        </li>
      </ul>
    </div>
  </header>
</section>