import { Component } from '@angular/core';
import { IHome } from './home.interface';
import { HomeService } from './home.service';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'page-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  providers: [ProductsService]
})

export class HomePage {
  public listAllProducts: any = []
  public coverSlides: any[] = [
    {
      id: 1,
      image: "https://k4j3j2s7.rocketcdn.me/bacola/wp-content/uploads/2021/05/slider-image-6.jpg",
      alignText: "left",
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      buttons: [
        {
          type: "addCart",
          color: "secondary"
        },
        {
          type: "shopNow",
          color: "primary"
        }
      ]
    },
    {
      id: 2,
      image: "https://k4j3j2s7.rocketcdn.me/bacola/wp-content/uploads/2021/05/slider-image-7.jpg",
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      buttons: [
        {
          type: "addCart",
          color: "secondary"
        },
        {
          type: "viewItem",
          color: "primary"
        }
      ]
    }
  ];

  constructor(
    private productsService: ProductsService
  ) { }

  ngOnInit() {
    this.productsService.getAllProducts().then(response => this.listAllProducts = response)
  }
}
