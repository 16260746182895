<section class="page-content">
  <div class="container">
    <div class="form-access bg-light-subtle mx-auto px-4 py-5 pb-4 rounded-1">
      <header class="text-center mb-5">
        <figure class="logotype mx-auto mb-4" [routerLink]="['/']" role="button">
          <img src="{{environment.app.assets}}/images/logotype-large.svg" alt="Logotipo">
        </figure>
        <h3 class="fs-4 fw-bold text-body-tertiary mb-0">Inicio de sesión</h3>
      </header>
      <form-login></form-login>
    </div>
    <p class="copy m-0 pt-3">Design by <a href="//codemonkey.com.mx" target="_blank"
        rel="noopener noreferrer">codemonkey.com.mx</a></p>
  </div>
</section>